import React from "react";
import { Link } from "gatsby";

export const Hero = ({ heading, subheading }) => (
  <div className="pad hero" id="home">
    <div class = "pad overlay">
    <h1 className="h1">{heading}</h1>

    <div className="h2">{subheading}</div>
    <Link className="btn w-button" to="/about-us">
      Learn more
    </Link>
    </div>
  </div>
);
