import React from "react";

import styled from "@emotion/styled";

const Break = styled.span`
  display: block;
`;
const Strong = styled.span`
  font-weight: bold;
`;

const features = [
  {
    title: (
      <>
        <Break>Flexible</Break>
        <Break>Modelling</Break>
      </>
    ),
    text: (
      <>
        Our user-friendly model editor allows fast reaction configuration. No
        coding required!
      </>
    ),
    image: "/img/atomic_icon.svg",
  },
  {
    title: (
      <>
        <Break>Kinetic</Break>
        <Break>Understanding</Break>
      </>
    ),
    text: (
      <>
        Rapid convergence to experimental data allows the identification and
        visualisation of kinetic parameters in any system.
      </>
    ),
    image: "/img/research_icon.svg",
  },
  {
    title: (
      <>
        <Break>Accessible</Break>
        <Break>Kinetics</Break>
      </>
    ),
    text: (
      <>
        Our easy-to-use software means anyone can conduct their own kinetic
        analysis.
      </>
    ),
    image: "/img/like_icon.svg",
  },
];

const Feature = ({ title, text, image }) => (
  <div className="usp">
    <div className="icon">
      <img alt="" height="100" src={image} />
    </div>

    <h1 className="h4">{title}</h1>

    <p className="copy usp1">{text}</p>
  </div>
);

export const Features = () => (
  <div className="pad" id="why">
    <h1 className="h3">Why use Compunetics?</h1>

    <div className="flex w-container">
      {features.map((props, idx) => (
        <Feature key={idx} {...props} />
      ))}
    </div>
  </div>
);
