import { css } from "@emotion/core";
import { Link as GatsbyLink } from "gatsby";

import React from "react";
import { Link, Box, Button, Input, Text } from "theme-ui";

const h3style = css`
  margin-top: 0px;
  margin-bottom: 40px;
  font-family: Rubik, sans-serif;
  font-size: 48px;
  line-height: 1.2;
  font-weight: 500;
  text-align: center;
`;

const h3whiteStyle = css`
  margin-bottom: 30px;
  color: #fff;
  font-weight: 500;
`;

export const Contact = () => (
  <div className="pad contact" id="contact">
    <div className="form w-container">
      <h2 css={[h3style, h3whiteStyle]}>Get in touch</h2>

      <Box>
        <form method="post" netlify-honeypot="bot-field" data-netlify="true">
          {/* Netlify metadata */}
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />

          {/* Name */}
          <Box>
            <label htmlFor="name">
              <Text variant="secondary" sx={{ fontWeight: "bold" }}>
                Name
              </Text>
              <Input type="text" name="name" id="name" required mt={1} />
            </label>
          </Box>

          {/* Email */}
          <Box mt={3}>
            <label htmlFor="email">
              <Text variant="secondary" sx={{ fontWeight: "bold" }}>
                Email
              </Text>
              <Input type="email" name="email" id="email" required mt={1} />
            </label>
          </Box>

          {/* Message */}
          <Box mt={3}>
            <label>
              <Text variant="secondary" sx={{ fontWeight: "bold" }}>
                Message
              </Text>
              <Input
                type="text"
                as="textarea"
                name="message"
                id="message"
                required
                mt={1}
              />
            </label>
          </Box>

          <Text variant="secondary" mt={3}>
            By submitting your details, you are confirming you agree to our{" "}
            <Link as={GatsbyLink} to="privacy-policy" sx={{ color: "primary" }}>
              privacy policy
            </Link>
            .
          </Text>

          <Button type="submit" mt={3}>
            Submit
          </Button>
        </form>
      </Box>
    </div>
  </div>
);
